import { navigate } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import { Offline, Online } from 'react-detect-offline';
import { connect } from 'react-redux';

import { PAGE_CATEGORIES } from '../../../../constants/router';
import { removeAllDb } from '../../../../database/init';
import { initDatabaseCentralizedImages, initDatabaseMain, initDatabaseProduct } from '../../../../database/reducer';
import { adminLogout } from '../../../../reducers/admin';
import { logout } from '../../../../services/admin';
import { getCurrentUser } from '../../../../services/auth';
import { clearCart } from '../../../features/cart/reducer';
import { resetState } from '../../../features/productState/reducer';

const Sync = ({
    adminLogout,
    clearCart,
    initDatabaseCentralizedImages,
    initDatabaseMain,
    initDatabaseProduct,
    resetState,
}) => {
    function handleLogout() {
        adminLogout();
        logout();
        navigate(PAGE_CATEGORIES);
    }

    function runSync() {
        const { mainDbName, password, productDbName, username } = getCurrentUser();
        handleLogout();
        // clear cart store
        clearCart();
        // Clear productState
        resetState();
        // Delete DB
        removeAllDb().then(() => {
            const user = { password, username };

            // clear store database
            initDatabaseMain({ name: mainDbName, ...user }).then(() => {
                initDatabaseProduct({ name: productDbName, ...user }).then(() => {
                    initDatabaseCentralizedImages({ ...user }).then(() => {
                        console.info('Sync ok');
                        handleLogout();
                    });
                });
            });
        });
    }

    return (
        <div className='glob-sync'>
            <h2>Synchroniser la base de produits</h2>
            <Online>
                <button className='border' onClick={runSync}>
                    <div className='icon icon-sync' />
                    <div className='title-subcat'>Synchroniser</div>
                </button>
            </Online>
            <Offline>
                <div className='warning'>
                    Vous êtes actuellement hors ligne, <br /> une connexion internet est nécessaire.
                </div>
            </Offline>
        </div>
    );
};

Sync.propTypes = {
    adminLogout: PropTypes.func.isRequired,
    clearCart: PropTypes.func.isRequired,
    initDatabaseCentralizedImages: PropTypes.func.isRequired,
    initDatabaseMain: PropTypes.func.isRequired,
    initDatabaseProduct: PropTypes.func.isRequired,
    resetState: PropTypes.func.isRequired,
};

const mapDispatch = {
    adminLogout,
    clearCart,
    initDatabaseCentralizedImages,
    initDatabaseMain,
    initDatabaseProduct,
    resetState,
};

export default connect(null, mapDispatch)(Sync);
