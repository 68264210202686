import React, { useEffect, useState } from 'react';

import useKioskDb from '../../../../database/kiosk/useKioskDb';
import CardCategory from '../../../components/CardCategory';

function List() {
    const [activeCategories, setActiveCategories] = useState([]);
    const [time, setTime] = useState('0s');
    const db = useKioskDb();

    useEffect(() => {
        const timer = setTimeout(() => {
            setTime('.5s');
        }, 1000);

        db.allDocs({ include_docs: true }).then((allNodes) => {
            const categories = allNodes.rows[0].doc.catalogue.categories;
            const activeCategories = categories.filter(categorie => categorie.active);
            console.log('Categorie list:', categories, activeCategories);
            setActiveCategories(activeCategories);
        });

        return () => clearTimeout(timer);
    }, [db]);

    return (
        <div
            className={`categories-list ${activeCategories.length > 10 ? 'display-4-by-4' : undefined}`}
            style={{ animationDuration: time }}
        >
            {activeCategories.map((cat) => (
                <CardCategory key={`home-cat-${cat.id}`} {...cat} />
            ))}
        </div>
    );
}

export default List;
